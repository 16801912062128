import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLock,
  MdAssignment,
  MdPeople,
  MdHeadsetMic,
  MdWork, // Task Request
  MdWorkOutline, // HR
  MdShoppingCart, // Order Fulfillment
  MdSettingsInputComposite, // Service Status
  MdBugReport,
  MdPlace,
  MdCheckCircle,
  MdTrendingUp,
  MdEqualizer,
  MdLabel,
  MdOutlineFlag
} from "react-icons/md";
import GenericLayout from 'layouts/generic';
// Use these icons in your application as needed.

// Admin Imports
// import MainDashboard from "views/admin/default";
// import Profile from "views/admin/profile";
import CustomerList from "views/admin/customerList";
import SupportList from "views/admin/supportList";
import AddSupportMember from "views/admin/addSupportMember";
import EditMember from "views/admin/editMember";
import CarrierPerformance from "views/admin/carrierPerformance";
import AllShipmentSummary from "views/admin/allShipmentSummary";

//Customer Imports
import Claims from "views/customer/claims";
import SubmittedClaims from "views/customer/submittedClaims";
import CustomerDashboard from "views/customer/customerDashboard";
import DashboardStats from "views/customer/dashboardStats";
import ClaimsCustomerSLA from "views/customer/claimsSLA";


//Support Imports
import CreateTask from "views/support/createTask";
import TaskList from "views/support/taskList";
import ViewJob from "views/support/viewJob";
import JobInvoice from "views/support/jobInvoice";
import ProfitablityReport from "views/support/profitabilityReport";
import ViewJobProfit from "views/support/viewJobProfit";
import SupportClaims from "views/support/claims";
import SupportSubmittedClaims from "views/support/submittedClaims";
import ViewClaim from "views/support/viewClaim";
import ClaimsSLA from "views/support/claimsSLA";
import ProjectionsReports from "views/support/projectionsReports";
import EmployeeDashboard from "views/generic/dashboard";
import UpdateProfile from "views/generic/updateProfile";

// Auth Imports
import SignInCentered from "views/auth/signIn";
// import Register from "views/auth/register";
import ForgotPassword from "views/auth/forgotPassword";
import ResetPassword from "views/auth/resetPassword";

//Operations Imports
import OpsTaskList from "views/operations/taskList";
import OpsViewJob from "views/operations/viewJob";
import ShipmentData from "views/admin/shipmentData";
import TrackingInfo from "views/customer/trackingInfo";
import CO2EmissionsReport from "views/admin/co2EmissionsReport";
import DimsReportBySKU from "views/support/dimsReportBySKU";
import DimsCapturedReportBySKU from "views/support/dimsCapturedReportBySKU";
import DimsReportByClient from "views/support/dimsReportByClient";
import DimsDataCapture from "views/support/dimsDataCapture";
import QCDataCapture from "views/support/qcDataCapture";
import QCAdminViewReport from "views/support/qcAdminViewReport";
import QCClientViewReport from "views/customer/qcClientReport";

import SupportCarrierPerformance from "views/support/carrierPerformance";
import SupportAllShipmentSummary from "views/support/allShipmentSummary";
import SupportCO2EmissionsReport from "views/support/co2EmissionsReport";
import SupportShipmentData from "views/support/shipmentData";
import AddInboundProjections from "views/customer/addInboundProjections";
import AddOutboundProjections from "views/customer/addOutboundProjections";
import AddReturnsProjections from "views/customer/addReturnsProjections";
import AddTaskProjections from "views/customer/addTaskProjections";
import AddCustomer from "views/admin/addCustomer";
import GenerateDPDLabels from "views/support/generateDPDLabels";
import AddAddressLabels from "views/support/addAddressLabels";
import ListAddresses from "views/support/listAddresses";
import DPDLabelsList from "views/support/dpdLabelsList";
import OpsDPDLabelsList from "views/operations/dpdLabelsList";
import OpsGenerateDPDLabels from "views/operations/generateDPDLabels";
import WhiteListIPVariable from "views/admin/whitelistIpVariable";


import CustomerCarrierPerformance from "views/customer/carrierPerformance";
import CustomertAllShipmentSummary from "views/customer/allShipmentSummary";
import CustomerCO2EmissionsReport from "views/customer/co2EmissionsReport";
import CustomerShipmentData from "views/customer/shipmentData";
import AddTask from "views/customer/addTask";
import StockInfoReport from "views/support/stockInfoReport";
import TaskListV2 from "views/support/taskListV2";
import ViewTask from "views/support/viewTask";
import PapierLabels from "views/support/papierLabels";
const routes = [
  {
    name: "What's New",
    layout: "/generic",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: EmployeeDashboard,
    layoutComponent: GenericLayout,
    accessRole: "Support,Admin,Operations",
  },
  {
    name: "What's New",
    layout: "/customer",
    path: "/whats-new",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: CustomerDashboard,
    layoutComponent: GenericLayout,
    accessRole: "Customer",
  },
  {
    name: "Dashboard",
    layout: "/customer",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: DashboardStats,
    layoutComponent: GenericLayout,
    accessRole: "Customer",
  },
  {
    name: "Go Green Portal",
    layout: "external",
    path: "https://go.greenfulfilment.co.uk/",
    icon: <Icon as={MdShoppingCart} width='20px' height='20px' color='inherit' />,
    external: true,
    accessRole: "Admin,Support,Operations,Customer",
  },
  // {
  //   name: "Dashboard 1",
  //   layout: "/admin",
  //   path: "/dashboard-1",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: MainDashboard,
  //   accessRole: "Admin",
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  //   hideInMenu: true,
  // },
   {
    name: "Update Profile",
    layout: "/generic",
    path: "/update-profile",
    component: UpdateProfile,
    layoutComponent: GenericLayout,
    accessRole: "Support,Admin,Operations,Customer",
    hideInMenu: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    hideInMenu: true,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ForgotPassword,
    hideInMenu: true,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ResetPassword,
    hideInMenu: true,
  },
  {
    name: "Submit New Claim",
    layout: "/customer",
    path: "/submit-claim",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: Claims,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "Submitted Claims",
    layout: "/customer",
    path: "/claims",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: SubmittedClaims,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "Submit New Claim",
    layout: "/support",
    path: "/submit-claim",
    component: SupportClaims,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Claims SLA",
    layout: "/support",
    path: "/claims-sla",
    component: ClaimsSLA,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Claims SLA",
    layout: "/customer",
    path: "/claims-sla",
    component: ClaimsCustomerSLA,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "Submitted Claims",
    layout: "/support",
    path: "/claims",
    component: SupportSubmittedClaims,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "View Claim",
    layout: "/support",
    path: "/view-claim",
    component: ViewClaim,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Customers",
    layout: "/admin",
    path: "/customer-list",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    component: CustomerList,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Support Team",
    layout: "/admin",
    path: "/support-team-list",
    icon: <Icon as={MdHeadsetMic} width='20px' height='20px' color='inherit' />,
    component: SupportList,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Edit Member",
    layout: "/admin",
    path: "/edit-member",
    component: EditMember,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Add Support Member",
    layout: "/admin",
    path: "/add-support-member",
    icon: <Icon as={MdHeadsetMic} width='20px' height='20px' color='inherit' />,
    component: AddSupportMember,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Add Customer",
    layout: "/admin",
    path: "/add-customer",
    icon: <Icon as={MdHeadsetMic} width='20px' height='20px' color='inherit' />,
    component: AddCustomer,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Create Job",
    layout: "/support",
    path: "/create-task",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: CreateTask,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Job List",
    layout: "/support",
    path: "/task-list",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: TaskList,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "View Job",
    layout: "/support",
    path: "/view-job",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: ViewJob,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "View Job Profit",
    layout: "/support",
    path: "/view-job-profit",
    component: ViewJobProfit,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Profitablity Reports",
    layout: "/support",
    path: "/profitablility-report",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: ProfitablityReport,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Job Invoices",
    layout: "/support",
    path: "/job-invoice",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: JobInvoice,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Job List",
    layout: "/operations",
    path: "/task-list",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: OpsTaskList,
    accessRole: "Operations",
    hideInMenu: true,
    
  },
  {
    name: "View Job",
    layout: "/operations",
    path: "/view-job",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: OpsViewJob,
    accessRole: "Operations",
    hideInMenu: true,
  },
  {
    name: "Carrier Performance",
    layout: "/admin",
    path: "/carrier-performance",
    component: CarrierPerformance,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Shipment Summary",
    layout: "/admin",
    path: "/shipment-summary",
    component: AllShipmentSummary,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Shipment Data",
    layout: "/admin",
    path: "/shipment-data",
    component: ShipmentData,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "CO2e Emissions Report",
    layout: "/admin",
    path: "/co2-emissions-report",
    component: CO2EmissionsReport,
    accessRole: "Admin",
    hideInMenu: true,
  },
  {
    name: "Carrier Performance",
    layout: "/support",
    path: "/carrier-performance",
    component: SupportCarrierPerformance,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Shipment Summary",
    layout: "/support",
    path: "/shipment-summary",
    component: SupportAllShipmentSummary,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Shipment Data",
    layout: "/support",
    path: "/shipment-data",
    component: SupportShipmentData,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "CO2e Emissions Report",
    layout: "/support",
    path: "/co2-emissions-report",
    component: SupportCO2EmissionsReport,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Carrier Performance",
    layout: "/customer",
    path: "/carrier-performance",
    component: CustomerCarrierPerformance,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "Shipment Summary",
    layout: "/customer",
    path: "/shipment-summary",
    component: CustomertAllShipmentSummary,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "Shipment Data",
    layout: "/customer",
    path: "/shipment-data",
    component: CustomerShipmentData,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "CO2e Emissions Report",
    layout: "/customer",
    path: "/co2-emissions-report",
    component: CustomerCO2EmissionsReport,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "UnCaptured Report By SKU",
    layout: "/support",
    path: "/dims-uncaptured-report-by-sku",
    component: DimsReportBySKU,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Captured Report By SKU",
    layout: "/support",
    path: "/dims-captured-report-by-sku",
    component: DimsCapturedReportBySKU,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "Report By Client",
    layout: "/support",
    path: "/dims-report-by-client",
    component: DimsReportByClient,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "DIMs Data Capture",
    layout: "/support",
    path: "/dims-data-capture",
    component: DimsDataCapture,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "UnCaptured Report By SKU",
    layout: "/operations",
    path: "/dims-uncaptured-report-by-sku",
    component: DimsReportBySKU,
    accessRole: "Operations",
    hideInMenu: true,
  },
  {
    name: "Captured Report By SKU",
    layout: "/operations",
    path: "/dims-captured-report-by-sku",
    component: DimsCapturedReportBySKU,
    accessRole: "Operations",
    hideInMenu: true,
  },
  {
    name: "Report By Client",
    layout: "/operations",
    path: "/dims-report-by-client",
    component: DimsReportByClient,
    accessRole: "Operations",
    hideInMenu: true,
  },
  {
    name: "DIMs Data Capture",
    layout: "/operations",
    path: "/dims-data-capture",
    component: DimsDataCapture,
    accessRole: "Operations",
    hideInMenu: true,
  },
  {
    name: "QC Data Capture",
    layout: "/support",
    path: "/qc-data-capture",
    component: QCDataCapture,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "QC Admin Report",
    layout: "/support",
    path: "/qc-admin-report",
    component: QCAdminViewReport,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "QC Data Capture",
    layout: "/operations",
    path: "/qc-data-capture",
    component: QCDataCapture,
    accessRole: "Operations",
    hideInMenu: true,
  },
  {
    name: "QC Admin Report",
    layout: "/operations",
    path: "/qc-admin-report",
    component: QCAdminViewReport,
    accessRole: "Operations",
    hideInMenu: true,
  },
  {
    name: "Inbound Projections",
    layout: "/customer",
    icon: <Icon as={MdCheckCircle} width='20px' height='20px' color='inherit' />,
    path: "/add-inbound-projections",
    component: AddInboundProjections,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "Outbound Projections",
    layout: "/customer",
    icon: <Icon as={MdCheckCircle} width='20px' height='20px' color='inherit' />,
    path: "/add-outbound-projections",
    component: AddOutboundProjections,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "Returns Projections",
    layout: "/customer",
    icon: <Icon as={MdCheckCircle} width='20px' height='20px' color='inherit' />,
    path: "/add-returns-projections",
    component: AddReturnsProjections,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "Task Projections",
    layout: "/customer",
    icon: <Icon as={MdCheckCircle} width='20px' height='20px' color='inherit' />,
    path: "/add-task-projections",
    component: AddTaskProjections,
    accessRole: "Customer",
    hideInMenu: true,
  },
  {
    name: "Task Request",
    layout: "/support",
    path: "/task-management",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Create Job",
        layout: "/support",
        path: "/create-task",
        component: CreateTask,
        accessRole: "Support",
      },
      {
        name: "Task List",
        layout: "/support",
        path: "/task-list",
        component: TaskList,
        accessRole: "Support",
      },
      {
        name: "Profitablity Reports",
        layout: "/support",
        path: "/profitablility-report",
        component: ProfitablityReport,
        accessRole: "Support",
      },
      {
        name: "Job Invoices",
        layout: "/support",
        path: "/job-invoice",
        component: JobInvoice,
        accessRole: "Support",
      },
    ],
    accessRole: "Support,Admin",
  },
  {
    name: "Task Request",
    layout: "/operations",
    path: "/task-management",
    icon: <Icon as={MdWork} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Job List",
        layout: "/operations",
        path: "/task-list",
        component: OpsTaskList,
        accessRole: "Operations",
        
      }
    ],
    accessRole: "Operations",
  },
  {
    name: "Claims",
    layout: "/customer",
    path: "/claims",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Submit New Claim",
        layout: "/customer",
        path: "/submit-claim",
       
        component: Claims,
        accessRole: "Customer",
      },
      {
        name: "Submitted Claims",
        layout: "/customer",
        path: "/claims",
      
        component: SubmittedClaims,
        accessRole: "Customer",
      },
    ],
    accessRole: "Customer",
  },
  {
    name: "Claims",
    layout: "/support",
    path: "/claims",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Submit New Claim",
        layout: "/support",
        path: "/submit-claim",
       
        component: SupportClaims,
        accessRole: "Support",
      },
      {
        name: "Submitted Claims",
        layout: "/support",
        path: "/claims",
        component: SupportSubmittedClaims,
        accessRole: "Support",
      }
    ],
    accessRole: "Support",
  },
  {
    name: "Tools",
    layout: "external",
    path: "/account-tools",
    icon: <Icon as={MdWorkOutline} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Freshdesk",
        layout: "external",
        path: "https://greenfulfilment.freshdesk.com/a/",
        accessRole: "Admin,Support,Operations",
        external: true
      },
      {
        name: "Freshsales",
        layout: "external",
        path: "https://greenfulfilment.freshworks.com/crm/sales",
        accessRole: "Admin,Support,Operations",
        external: true
      },
      {
        name: "Variable Shipping",
        layout: "external",
        path: "https://variable.greenfulfilment.co.uk",
        accessRole: "Admin,Support,Operations",
        external: true
      },
      {
        name: "Phone Reports",
        layout: "external",
        path: "https://4reports.4com.im/",
        accessRole: "Admin,Support,Operations",
        external: true
      }
    ],
    accessRole: "Admin,Support,Operations",
  },
  {
    name: "HR",
    layout: "external",
    path: "/hr",
    icon: <Icon as={MdWorkOutline} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Timesheet",
        layout: "external",
        path: "https://v2.trackmytime.co.uk/greenfulfilment",
        accessRole: "Admin,Support,Operations",
        external: true
      },
      {
        name: "Time Off",
        layout: "external",
        path: "https://greenfulfilment.charliehr.com/",
        accessRole: "Admin,Support,Operations",
        external: true
      },
      {
        name: "Training Portal",
        layout: "external",
        path: "https://go.accessacloud.com/o/greenfulfilment/",
        accessRole: "Admin,Support,Operations",
        external: true
      }
    ],
    accessRole: "Admin,Support,Operations",
  },
  {
    name: "Shipping",
    layout: "/admin",
    path: "/shipping-module",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Carrier Performance",
        layout: "/admin",
        path: "/carrier-performance",
        component: CarrierPerformance,
        accessRole: "Admin",
      },
      {
        name: "Shipment Summary",
        layout: "/admin",
        path: "/shipment-summary",
        component: AllShipmentSummary,
        accessRole: "Admin",
      },
      {
        name: "CO2e Emissions Report",
        layout: "/admin",
        path: "/co2-emissions-report",
        component: CO2EmissionsReport,
        accessRole: "Admin",
      }
    ],
    accessRole: "Admin",
  },
  {
    name: "Track Parcel",
    layout: "/support",
    path: "/track-your-parcel",
    icon: <Icon as={MdPlace} width='20px' height='20px' color='inherit' />,
    component: TrackingInfo,
    accessRole: "Admin,Support",
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
    category: true,
    items: [
      {
        name: "Customers",
        layout: "/admin",
        path: "/customer-list",
        component: CustomerList,
        accessRole: "Admin",
      },
      {
        name: "Support Team",
        layout: "/admin",
        path: "/support-team-list",
        component: SupportList,
        accessRole: "Admin",
      },
    ],
    accessRole: "Admin",
  },
  {
    name: "Report IT Issue",
    layout: "external",
    path: "https://docs.google.com/forms/d/e/1FAIpQLSezHAwr-wfEJBapZMnZ2PzIaYPKZpb7gg18GjAE727z1wL-jQ/viewform?usp=sf_link",
    icon: <Icon as={MdBugReport} width='20px' height='20px' color='inherit' />,
    external: true,
    accessRole: "Admin,Support,Operations",
  },
  {
    name: "Track Your Parcel",
    layout: "/customer",
    path: "/track-your-parcel",
    icon: <Icon as={MdPlace} width='20px' height='20px' color='inherit' />,
    component: TrackingInfo,
    accessRole: "Customer",
  },
  {
    name: "DIMS Capture",
    layout: "/support",
    path: "/product-dims",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "UnCaptured Report By SKU",
        layout: "/support",
        path: "/dims-uncaptured-report-by-sku",
        component: DimsReportBySKU,
        accessRole: "Support",
      },
      {
        name: "Captured Report By SKU",
        layout: "/support",
        path: "/dims-captured-report-by-sku",
        component: DimsCapturedReportBySKU,
        accessRole: "Support",
      },
      {
        name: "Report By Client",
        layout: "/support",
        path: "/dims-report-by-client",
        component: DimsReportByClient,
        accessRole: "Support",
      },
      {
        name: "DIMs Data Capture",
        layout: "/support",
        path: "/dims-data-capture",
        component: DimsDataCapture,
        accessRole: "Support",
      },
    ],
    accessRole: "Support",
  },
  {
    name: "Quality Control",
    layout: "/support",
    path: "/qc-module",
    icon: <Icon as={MdCheckCircle} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "QC Data Capture",
        layout: "/support",
        path: "/qc-data-capture",
        component: QCDataCapture,
        accessRole: "Support",
      },
      {
        name: "QC Admin Report",
        layout: "/support",
        path: "/qc-admin-report",
        component: QCAdminViewReport,
        accessRole: "Support",
       
      },
    ],
    accessRole: "Support",
  },
  {
    name: "Quality Control",
    layout: "/customer",
    icon: <Icon as={MdCheckCircle} width='20px' height='20px' color='inherit' />,
    path: "/qc-client-report",
    component: QCClientViewReport,
    accessRole: "Customer",
    // hideInMenu: true,
  },
  {
    name: "DIMS Capture",
    layout: "/operations",
    path: "/product-dims",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "UnCaptured Report By SKU",
        layout: "/operations",
        path: "/dims-uncaptured-report-by-sku",
        component: DimsReportBySKU,
        accessRole: "Operations",
      },
      {
        name: "Captured Report By SKU",
        layout: "/operations",
        path: "/dims-captured-report-by-sku",
        component: DimsCapturedReportBySKU,
        accessRole: "Operations",
      },
      {
        name: "Report By Client",
        layout: "/operations",
        path: "/dims-report-by-client",
        component: DimsReportByClient,
        accessRole: "Operations",
      },
      {
        name: "DIMs Data Capture",
        layout: "/operations",
        path: "/dims-data-capture",
        component: DimsDataCapture,
        accessRole: "Operations",
      },
    ],
    accessRole: "Operations",
  },
  {
    name: "Quality Control",
    layout: "/operations",
    path: "/qc-module",
    icon: <Icon as={MdCheckCircle} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "QC Data Capture",
        layout: "/operations",
        path: "/qc-data-capture",
        component: QCDataCapture,
        accessRole: "Operations",
      },
      {
        name: "QC Admin Report",
        layout: "/operations",
        path: "/qc-admin-report",
        component: QCAdminViewReport,
        accessRole: "Operations",
       
      },
    ],
    accessRole: "Operations",
  },
  {
    name: "Shipping",
    layout: "/support",
    path: "/shipping-module",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Carrier Performance",
        layout: "/support",
        path: "/carrier-performance",
        component: SupportCarrierPerformance,
        accessRole: "Support",
      },
      {
        name: "Shipment Summary",
        layout: "/support",
        path: "/shipment-summary",
        component: SupportAllShipmentSummary,
        accessRole: "Support",
      },
      {
        name: "CO2e Emissions Report",
        layout: "/support",
        path: "/co2-emissions-report",
        component: SupportCO2EmissionsReport,
        accessRole: "Support",
      }
    ],
    accessRole: "Support",
  },
  {
    name: "Projections",
    layout: "/customer",
    path: "/projections",
    icon: <Icon as={MdTrendingUp} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Inbound Projections",
        layout: "/customer",
        path: "/add-inbound-projections",
        component: AddInboundProjections,
        accessRole: "Customer",
      },
      {
        name: "Outbound Projections",
        layout: "/customer",
        path: "/add-outbound-projections",
        component: AddOutboundProjections,
        accessRole: "Customer",
      },
      {
        name: "Returns Projections",
        layout: "/customer",
        path: "/add-returns-projections",
        component: AddReturnsProjections,
        accessRole: "Customer",
      },
      {
        name: "Task Projections",
        layout: "/customer",
        path: "/add-task-projections",
        component: AddTaskProjections,
        accessRole: "Customer",
      },
    ],
    accessRole: "Customer",
  },
  {
    name: "Projection Reports",
    layout: "/support",
    path: "/projection-reports",
    icon: <Icon as={MdEqualizer} width='20px' height='20px' color='inherit' />,
    component: ProjectionsReports,
    accessRole: "Admin,Support",
  },

  {
    name: "Projection Reports",
    layout: "/operations",
    path: "/projection-reports",
    icon: <Icon as={MdEqualizer} width='20px' height='20px' color='inherit' />,
    component: ProjectionsReports,
    accessRole: "Operations",
  },

  {
    name: "Generate DPD Labels",
    layout: "/support",
    path: "/generate-dpd-labels",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: GenerateDPDLabels,
    accessRole: "Support",
    hideInMenu: true,
  },

  {
    name: "Add Address",
    layout: "/support",
    path: "/add-address",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: AddAddressLabels,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "List Addresses",
    layout: "/support",
    path: "/list-addresses",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: ListAddresses,
    accessRole: "Support",
    hideInMenu: true,
  },
  {
    name: "DPD Labels List",
    layout: "/support",
    path: "/dpd-labels-list",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: DPDLabelsList,
    accessRole: "Support",
    hideInMenu: true,
  },
  // {
  //   name: "Add Task",
  //   layout: "/customer",
  //   path: "/add-task",
  //   component: AddTask,
  //   accessRole: "Customer",
  //   hideInMenu: true,
  // },
  // {
  //   name: "Task List",
  //   layout: "/support",
  //   path: "/list-task",
  //   component: TaskListV2,
  //   accessRole: "Support",
  //   hideInMenu: true,
  // },
  // {
  //   name: "View Task",
  //   layout: "/support",
  //   path: "/view-task",
  //   component: ViewTask,
  //   accessRole: "Support",
  //   hideInMenu: true,
  // },
  
  {
    name: "DPD Labels",
    layout: "/support",
    path: "/dpd-lables",
    icon: <Icon as={MdLabel} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "List Addresses",
        layout: "/support",
        path: "/list-addresses",
        component: ListAddresses,
        accessRole: "Support",
      },
      {
        name: "DPD Labels List",
        layout: "/support",
        path: "/dpd-labels-list",
        component: DPDLabelsList,
        accessRole: "Support",
      },
    ],
    accessRole: "Support",
  },
  {
    name: "Generate DPD Labels",
    layout: "/operations",
    path: "/generate-dpd-labels",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: OpsGenerateDPDLabels,
    accessRole: "Operations",
    hideInMenu: true,
  },
  {
    name: "DPD Labels List",
    layout: "/operations",
    path: "/dpd-labels-list",
    icon: <Icon as={MdLabel} width='20px' height='20px' color='inherit' />,
    component: OpsDPDLabelsList,
    accessRole: "Operations",
  },
  {
    name: "WhiteList IPs Variable",
    layout: "/admin",
    path: "/whitelist-ips-variable",
    icon: <Icon as={MdOutlineFlag} width='20px' height='20px' color='inherit' />,
    component: WhiteListIPVariable,
    accessRole: "Admin",
  },
  {
    name: "Shipping",
    layout: "/customer",
    path: "/shipping-module",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Carrier Performance",
        layout: "/customer",
        path: "/carrier-performance",
        component: CustomerCarrierPerformance,
        accessRole: "Customer",
      },
      {
        name: "Shipment Summary",
        layout: "/customer",
        path: "/shipment-summary",
        component: CustomertAllShipmentSummary,
        accessRole: "Customer",
      },
      {
        name: "CO2e Emissions Report",
        layout: "/customer",
        path: "/co2-emissions-report",
        component: CustomerCO2EmissionsReport,
        accessRole: "Customer",
      }
    ],
    accessRole: "Customer",
  },
  {
    name: "Stock Info",
    layout: "/support",
    path: "/stock-info-report",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: StockInfoReport,
    accessRole: "Support",
  },
  {
    name: "Tasks",
    layout: "/customer",
    path: "/task-module",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Add Task",
        layout: "/customer",
        path: "/add-task",
        component: AddTask,
        accessRole: "Customer",
      },
    ],
    accessRole: "Customer",
  },
  {
    name: "Tasks",
    layout: "/support",
    path: "/task-module",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Task List",
        layout: "/support",
        path: "/list-task",
        component: TaskListV2,
        accessRole: "Support",
      },
    ],
    accessRole: "Support",
  },
  {
    name: "Retailer Labels",
    layout: "/support",
    path: "/retailer-labels",
    icon: <Icon as={MdAssignment} width='20px' height='20px' color='inherit' />,
    component: PapierLabels,
    accessRole: "Support",
  },
  {
    name: "Service Status",
    layout: "external",
    path: "https://greenfulfilment.freshstatus.io/",
    icon: <Icon as={MdSettingsInputComposite} width='20px' height='20px' color='inherit' />,
    external: true,
    accessRole: "Admin,Support,Operations,Customer",
  },
];

export default routes;

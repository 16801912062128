import React, { useState, useEffect, useRef } from "react";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Wrap,
  WrapItem,
  Spinner,
  Text,
  Badge,
  IconButton,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Input,
  Select,
  Avatar,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { FaEye, FaTrash } from "react-icons/fa";

export default function TaskListV2() {
  const [loading, setIsLoading] = useState(false);
  const [clientFilter, setClientFilter] = useState([]);
  const [taskTypeFilter, setTaskTypeFilter] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedTaskType, setSelectedTaskType] = useState('');
  const [selectedTab, setSelectedTab] = useState("All");
  const [searchText, setSearchText] = useState("");

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [statusCounts, setStatusCounts] = useState(null);
  const [tabTasks, setTabTasks] = useState({
    All: [],
    Pending: [],
    "Ready To Start": [],
    "In Progress": [],
    Completed: [],
    Invoiced: [],
  });

  const toast = useToast();

  const cancelRef = useRef();
  const alertDialogRef = useRef();

  const openConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  // Fetch all tasks without pagination
  const fetchData = async (status, client, taskType, searchText) => {
    try {
      console.log('Fetching Tasks for:', status);

      setIsLoading(true);

      const token = localStorage.getItem("accessToken");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const params = {
        status: status,
      };

      if (client) {
        params.client = client;
      }

      if (taskType) {
        params.taskType = taskType;
      }

      if (searchText) {
        params.searchText = searchText;
      }

      const response = await axios.get(`${constants.apiUrl}/support/fetch-task-list`, {
        headers,
        withCredentials: true,
        params,
      });

      setTabTasks((prevTabTasks) => ({
        ...prevTabTasks,
        [status]: response.data.tasks, // Directly setting fetched data
      }));

      setStatusCounts(response.data.statusCounts);

    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFilterData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${constants.apiUrl}/support/fetch-task-filters`, {
        headers,
        withCredentials: true,
      });

      setClientFilter(response.data.clients);
      setTaskTypeFilter(response.data.taskTypes);

    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  // useEffect to fetch data when selectedTab, selectedClient, or selectedTaskType changes
  useEffect(() => {
    // Reset states when filters or tab change
    setIsLoading(true);
    setTabTasks({
      All: [],
      Pending: [],
      "Ready To Start": [],
      "In Progress": [],
      Completed: [],
      Invoiced: [],
    });

    // Fetch all data with updated filters
    fetchData(selectedTab, selectedClient, selectedTaskType, searchText);
  }, [selectedTab, selectedClient, selectedTaskType, searchText]);

  // Fetch filter data whenever changes
  useEffect(() => {
    fetchFilterData();
  }, []);

  // Handler for clicking the "Delete" icon
  const handleDeleteClick = (task) => {
    setTaskToDelete(task);
    openConfirmation();
  };

  // Handler for confirming the deletion
  const handleConfirmDelete = async () => {
    closeConfirmation();
    if (taskToDelete) {
      try {
        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.delete(`${constants.apiUrl}/admin/delete-task`, {
          headers,
          data: { id: taskToDelete.id },
        });

        console.log("Claim deleted successfully:", response.data);

        if (response.status === 200) {
          toast({
            position: "bottom-right",
            title: "Claim Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }

        // After successful deletion, refetch data
        fetchData(selectedTab, selectedClient, selectedTaskType, searchText);

      } catch (error) {
        console.error("Error deleting task:", error);
        if (error.response && error.response.status === 403) {
          toast({
            position: "bottom-right",
            title: "Forbidden: You do not have permission to delete the task",
            description: error.response.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            position: "bottom-right",
            title: "Error: Failed to delete the task",
            description: error.response?.data?.message || "An unexpected error occurred.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
  };

  function RenderTasks({ tasks }) {
    const borderColor = useColorModeValue("white", "gray.800");

    return (
      <Wrap spacing={4} style={{ whiteSpace: 'nowrap' }}>
        {tasks.length === 0 ? (
          <Text>No Tasks found.</Text>
        ) : (
          tasks.map((task) => (
            <WrapItem key={`${task.id}`}>
              <Box
                borderWidth="1px"
                borderRadius="lg"
                p={4}
                shadow="md"
                bg={borderColor}
                position="relative"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="250px"
              >
                <Box mt="auto" alignSelf="flex-end">
                  <Link
                    to={`/support/view-task?id=${task.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton
                      icon={<FaEye />}
                      aria-label="View"
                      colorScheme="blue"
                      variant="ghost"
                      size="sm"
                    />
                  </Link>
                  <IconButton
                    icon={<FaTrash />}
                    aria-label="Delete"
                    colorScheme="red"
                    variant="ghost"
                    size="sm"
                    onClick={() => handleDeleteClick(task)}
                  />
                </Box>
                <Box mb={2}>
                  <Text fontWeight="bold">Task ID: {task.id}</Text>
                </Box>
                <Box mb={2} display="flex" alignItems="center" gap={2}>
                <Badge colorScheme="green" fontWeight="bold" borderRadius="md" px={2}>
                    Est. Cost: £{task.estimated_overall_cost}
                </Badge>
                </Box>
                <Box mb={2} display="flex" alignItems="center" gap={2}>
                  <Badge
                    colorScheme={
                      task.task_priority === "Urgent - Sameday" ? "red" : 
                      task.task_priority === "High - Next Day" ? "yellow" : 
                      "orange"
                    }
                  >
                    {task.task_priority}
                  </Badge>
                  <Badge
                    colorScheme={task.task_status === "Completed" || task.task_status === "Invoiced" ? "green" : "red"}
                  >
                    {task.task_status}
                  </Badge>
                </Box>
                <Box mb={2} display="flex" alignItems="center" gap={2}>
                <Badge
                    colorScheme={
                        task.warehouse === "GLA3" ? "red" : 
                        task.warehouse === "GLA2" ? "yellow" : 
                        "orange"
                      }
                  >
                    {task.warehouse}
                  </Badge>
                  <Badge
                    colorScheme={"cyan"}
                  >
                    {task.task_type}
                  </Badge>
               
                </Box>
               
                {task.client && (
                  <Box mb={2} display="flex" alignItems="center" gap={2}>
                    <Avatar size="sm" name={task.client} />
                    <Text>{task.client}</Text>
                  </Box>
                )}
             
              </Box>
            </WrapItem>
          ))
        )}
      </Wrap>
    );
  }

  const handleTabChange = (index) => {
    const statuses = ["All", "Pending", "Ready To Start", "In Progress", "Completed", "Invoiced" ];
    const selectedStatus = statuses[index];
    setSelectedTab(selectedStatus);
    // The useEffect will handle fetching data
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} px="30px">
      <Flex mb={4} gap={4}>
        <Input
          placeholder="Search by Task ID"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Select
          placeholder="Filter by Client"
          value={selectedClient}
          onChange={(e) => setSelectedClient(e.target.value)}
        >
          {clientFilter.map((client) => (
            <option key={client} value={client}>
              {client}
            </option>
          ))}
        </Select>
        <Select
          placeholder="Filter by Task Type"
          value={selectedTaskType}
          onChange={(e) => setSelectedTaskType(e.target.value)}
        >
          {taskTypeFilter.map((taskType) => (
            <option key={taskType} value={taskType}>
              {taskType}
            </option>
          ))}
        </Select>
    
      </Flex>
          {console.log(statusCounts)};
      <Tabs index={["All", "Pending","Ready To Start", "In Progress", "Completed", "Invoiced"].indexOf(selectedTab)} onChange={handleTabChange}>
        <TabList justifyContent={"space-between"}>
          {["All", "Pending", "Ready To Start", "In Progress", "Completed", "Invoiced"].map((tabName) => (
            <Tab key={tabName}>{tabName} {statusCounts && statusCounts[tabName] ? `(${statusCounts[tabName]})` : "(0)"}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {["All", "Pending", "Ready To Start", "In Progress", "Completed", "Invoiced"].map((tabName) => (
            <TabPanel key={tabName}>
              {loading ? (
                <Flex justifyContent="center" alignItems="center">
                  <Spinner />
                </Flex>
              ) : (
                <RenderTasks tasks={tabTasks[tabName]} />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <AlertDialog
        isOpen={isConfirmationOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeConfirmation}
        motionPreset="slideInBottom"
        isCentered
        size="md"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Delete
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this Task? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeConfirmation}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

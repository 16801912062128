import React, { useEffect, useState, useMemo } from "react";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";
import {
  Flex,
  FormLabel,
  FormControl,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  Select,
  Input,
  Button,
  Box,
  useToast,
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp, FiDownload, FiUpload } from "react-icons/fi";
// Custom components
import Card from "components/card/Card";

export default function ComplexTable({ tableData }) {
  const columnsData = [
    {
      Header: "RETAILER",
      accessor: "retailer",
    },
    {
      Header: "PAPIER SKU",
      accessor: "papierSku",
    },
    {
      Header: "RETAILER SKU",
      accessor: "retailerSku",
    },
    {
      Header: "DESCRIPTION",
      accessor: "description",
    },
    {
      Header: "BARCODE",
      accessor: "barcode",
    },
    {
      Header: "BOX NUMBER",
      accessor: "boxNumber",
    },
    {
      Header: "BATCH NO",
      accessor: "batchNumber",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
    {
      Header: "WEIGHT",
      accessor: "weight",
    },
  ];

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [uploadedData, setUploadedData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
   const [deptNo, setDeptNo] = useState(null);
   const [dateTime, setDateTime] = useState(null);
   const [purchaseOrderNo, setPurchaseOrderNo] = useState(null);
   const [bookingRef, setBookingRef] = useState(null);
  const toast = useToast();
  const textColor = "gray.900";
  const borderColor = "gray.200";

  const columns = useMemo(() => columnsData, []); // Memoize columns
  const data = useMemo(() => {
    return uploadedData !== null ? uploadedData : tableData;
  }, [uploadedData, tableData]);
  

  // Create a filter function to handle search input
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setGlobalFilter(value); // Use react-table's global filter for search
  };

const generateLabels = async (data) => {
  const token = localStorage.getItem("accessToken");
  const { orderNumber } = data[0];
  const headers = { Authorization: `Bearer ${token}` };
 // Parse dateTime into dueDate and dueTime
 const parsedDate = new Date(dateTime);
 const dueDate = parsedDate.toLocaleDateString('en-GB', {
   day: '2-digit',
   month: '2-digit',
   year: '2-digit',
 }); // Format: DD/MM/YY
 const dueTime = parsedDate.toTimeString().slice(0, 5); // Format: HH:mm

 // Create a new array with updated values
 const updatedData = data.map(item => ({
   ...item,
   poNumber: purchaseOrderNo,
   bookingRef: bookingRef,
   dueDate: dueDate,
   dueTime: dueTime,
   deptNo: deptNo,
 }));


  try {
    // Call the single API to get both PDFs
    const response = await axios.post(
      `${constants.apiUrl}/support/generate-papier-labels`,
     
        updatedData, // Existing array
 
      { headers }
    );

    // Decode and download Cartons PDF
    const cartonsBlob = new Blob([Uint8Array.from(atob(response.data.cartonsPdf), c => c.charCodeAt(0))], { type: 'application/pdf' });
    const cartonsUrl = window.URL.createObjectURL(cartonsBlob);
    const cartonsLink = document.createElement('a');
    cartonsLink.href = cartonsUrl;
    cartonsLink.setAttribute('download', `${orderNumber}_cartons.pdf`);
    document.body.appendChild(cartonsLink);
    cartonsLink.click();
    document.body.removeChild(cartonsLink);

    // Decode and download Delivery PDF
    const deliveryBlob = new Blob([Uint8Array.from(atob(response.data.deliveryPdf), c => c.charCodeAt(0))], { type: 'application/pdf' });
    const deliveryUrl = window.URL.createObjectURL(deliveryBlob);
    const deliveryLink = document.createElement('a');
    deliveryLink.href = deliveryUrl;
    deliveryLink.setAttribute('download', `${orderNumber}_delivery_and_pallets.pdf`);
    document.body.appendChild(deliveryLink);
    deliveryLink.click();
    document.body.removeChild(deliveryLink);
  } catch (error) {
    console.error("Error generating labels:", error);
    toast({
      position: "top",
      title: "Error",
      description: error.response?.data?.message || "An error occurred while generating labels.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }
};

  

  // Function to download the table data as CSV
  const downloadCSV = () => {
    const csvRows = [];
    // Add header row
    const headers = columnsData.map((col) => col.Header);
    csvRows.push(headers.join(","));

    // Add data rows
      // Add data rows
  data.forEach((row) => {
    const values = columnsData.map((col) => {
      return col.accessor === "barcode" ? `'${row[col.accessor]}` : row[col.accessor]; // Add apostrophe to barcode values
    });
    csvRows.push(values.join(","));
  });

    // Create a Blob from the CSV string and trigger the download
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = data[0].orderId + "-boxes.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleUploadCSV = (event, data) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const token = localStorage.getItem("accessToken");

    const formData = new FormData();
    formData.append("file", file);

    setIsUploading(true);
    const { orderId, orderNumber, poNumber, bookingRef } = data;
    axios
      .post(
        `${constants.apiUrl}/support/upload-papier-boxes-csv?orderId=${orderId}&orderNumber=${orderNumber}&poNumber=${poNumber}&bookingRef=${bookingRef}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setIsUploading(false);
        if (response.status === 200) {

          setUploadedData(response.data);
          toast({
            position: "top",
            title: "CSV Uploaded Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            position: "top",
            title: "Error Uploading CSV, Please try again!",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setIsUploading(false);
        const errorMessage =
          error.response.data.message ||
          "Error Uploading CSV, Please try again!";
        toast({
          position: "top",
          title: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error uploading CSV:", error);
      })
      .finally(() => {
        setIsUploading(false);
        setSelectedFile(null);
        // fetchData();
      });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    state,
    setGlobalFilter, // Function to set the global filter value (search)
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 }, // Start from first page
    },
    useGlobalFilter, // Enables search functionality
    useSortBy, // Enables sorting functionality
    usePagination // Enables pagination
  );

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

            {/* Search Input */}
            <Input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="500px"
              ml={6}
            />
             <Button
              leftIcon={<FiDownload />}
              onClick={downloadCSV}
              colorScheme="blue"
              ml="65px"
            >
              Export
            </Button>
           
            <Button
              leftIcon={<FiUpload />}
              colorScheme="blue"
              isLoading={isUploading}
              ml="65px"
            >
              <input
                type="file"
                accept=".csv"
                onChange={(event) => handleUploadCSV(event, data[0])}
                style={{
                  position: "absolute",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
              Upload CSV
            </Button>
            {selectedFile && (
              <Flex mt={2} alignItems="center">
                <Text mr={2}>Selected File: {selectedFile.name}</Text>
              </Flex>
            )}
            </Flex>
            <Flex justify="center" align="center" wrap="wrap" gap="15px">

            <Flex direction="column" align="start">
    <label htmlFor="deptNoField">Purchase Order No.</label>
    <input
      type="text"
      id="purchaseOrderNoField"
      onChange={(e) => setPurchaseOrderNo(e.target.value)} // Replace setDeptNo with your handler
      style={{
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
      }}
    />
  </Flex>

  <Flex direction="column" align="start">
    <label htmlFor="deptNoField">Booking Ref</label>
    <input
      type="text"
      id="bookingRefField"
      onChange={(e) => setBookingRef(e.target.value)} // Replace setDeptNo with your handler
      style={{
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
      }}
    />
  </Flex>
  {/* Date and Time Field */}
  <Flex direction="column" ml="4" align="start">
    <label htmlFor="dateTimeField">Select Date & Time</label>
    <input
      type="datetime-local"
      id="dateTimeField"
      onChange={(e) => setDateTime(e.target.value)} // Replace setDateTime with your handler
      style={{
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
      }}
    />
  </Flex>

  {/* Dept No Field */}
  <Flex direction="column" align="start">
    <label htmlFor="deptNoField">Dept No</label>
    <input
      type="text"
      id="deptNoField"
      onChange={(e) => setDeptNo(e.target.value)} // Replace setDeptNo with your handler
      style={{
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
      }}
    />
  </Flex>
  <Flex direction="column" align="start">
  {/* Generate Labels Button */}
  <Button onClick={() => generateLabels(data)} colorScheme="brand" marginTop={"16px"}>
    Generate Labels
  </Button>
  </Flex>
</Flex>


          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            style={{ overflowX: "auto" }}
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td colSpan={columnsData.length} textAlign="center">
                    No data available.
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        let cellContent = cell.value;

                        cellContent = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{ sm: "14px" }}
                            maxH="30px !important"
                            py="8px"
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {cellContent}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>

          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
            <Flex>
              <Button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                variant="link"
                color={textColor}
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                variant="link"
                color={textColor}
              >
                {"<"}
              </Button>

              <Button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                variant="link"
                color={textColor}
              >
                {">"}
              </Button>
              <Button
                onClick={() => gotoPage(pageOptions.length - 1)}
                disabled={!canNextPage}
                variant="link"
                color={textColor}
              >
                {">>"}
              </Button>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                Page{" "}
                <Text as="span" color={textColor}>
                  {pageIndex + 1} of {pageOptions.length}
                </Text>{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}

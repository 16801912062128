import React, { useState, useEffect } from "react";
import { constants } from "constants/constants";
import axios from "components/axios/axiosInstance";

import {
  Box,
  Spinner,
  Text,
  Badge,
  Flex,
  Input,
  Button,
  useToast,
  FormLabel,
  FormControl,
  Tooltip,
  SimpleGrid
} from "@chakra-ui/react";

import { MdEco, MdHelpOutline } from 'react-icons/md';
import ComplexTable from "./components/ComplexTable";

export default function PapierLabels() {

  const toast = useToast();

  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }
  
    const paramsArray = queryString.split("&");
    const params = {};
  
    paramsArray.forEach(param => {
      const [key, value] = param.split("=");
      const decodedValue = decodeURIComponent(value.replace(/\+/g, ' '));
      params[key] = decodedValue;
    });
  
    return params;
  };
  
  
  const windowUrl = window.location.href;
    
  const queryParams = getQueryParams(windowUrl);
  const orderNumberParam = queryParams["orderNumber"];

  const [orderNumber, setOrderNumber] = useState(orderNumberParam ? orderNumberParam : "");
  const [responseData, setResponseData] = useState(null);
  const [loading, setIsLoading] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Call the API with the provided order number
    axios
      .get(`${constants.apiUrl}/support/fetch-papier-box-details`, { headers, withCredentials: true, params: { orderNumber: orderNumber } })
      .then((response) => {
        const responseData = response.data;
       
        setResponseData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Papier Box details:", error);
        toast({
          position: "top",
          title: "Error",
          description: error.response.data.message || "An error occurred while fetching Papier Box Details.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });

        setIsLoading(false);
        setResponseData(null);
      });
  };

  useEffect(() => {
    if (orderNumberParam) {
      handleFormSubmit({ preventDefault: () => {} }); // Pass an empty object that has preventDefault method
    }
  }, []); // Run only once when component mounts
  

  if (loading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" thickness="4px" color="green.500" />
      </Flex>
    );
  }


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex direction={{ base: "column", md: "row" }} gridGap="20px">

        <FormControl mt={6} >
          <FormLabel textAlign={"end"}>Enter Order Number</FormLabel>

        </FormControl>
        <FormControl mt={4}>
          <Input
            type="text"
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
            required
            variant="brand"
          />
        </FormControl>
        {/* Add more dropdowns as needed for warehouse, department, client, task type, and billing type */}
        <FormControl mt={4}>
          <Button variant="brand" size='md' onClick={handleFormSubmit}>
            Submit
          </Button>

        </FormControl>
      </Flex>
        {responseData && (
             <SimpleGrid gap='20px' mt="10px" mb='20px'>
                 <ComplexTable tableData={responseData} />
             </SimpleGrid>
        )}
    </Box>

  );
}

import React, { useState, useEffect } from 'react';
import axios from "components/axios/axiosInstance";
import { constants } from "constants/constants";
import {
  Box,
  Input,
  Flex,
  Button,
  Select,
  Textarea,
  Stack,
  useToast,
  Text,
  UnorderedList,
  ListItem,

} from '@chakra-ui/react';
import Card from "components/card/Card";
const AddTask = () => {
  const [taskTypeData, setTaskTypeData] = useState([]);
  const [jobPriorityData, setJobPriorityData] = useState([]);
  const [taskChargesData, setTaskChargesData] = useState({});
  const [attachmentNames, setAttachmentNames] = useState([]);
  const [formData, setFormData] = useState({
    taskType: '',
    taskName: '',
    taskDescription: '',
    units: 1,
    priority: '',
    estimatedCost: 0.00,
    attachments: [],
    videoUrl: '',
  });
  const [products, setProducts] = useState([{ sku: '', quantity: '' }]); // State for product SKUs and quantities
  const [fetchedSKUs, setFetchedSKUs] = useState([]); // State to store fetched SKUs
  const toast = useToast();
  const textColor = "gray.900";
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    if (name === "taskType") {
      // Find the charge for the selected task type
      const taskCharge = taskChargesData[value.toLowerCase().replace(/\s+/g, '')] || 0;
      const units = parseInt(formData.units) || 0;
      ;
      // Calculate the estimated cost
      const estimatedCost = taskCharge * units;
      console.log(taskCharge, units, estimatedCost);
      // Update the form data, including the estimated cost
      setFormData({
        ...formData,
        taskType: value,
        estimatedCost: estimatedCost.toFixed(2)
      });
    } else if (name === "units") {
      // If the units change, recalculate the estimated cost
      const taskCharge = taskChargesData[formData.taskType.toLowerCase().replace(/\s+/g, '')] || 0;
      const estimatedCost = taskCharge * parseInt(value);
      console.log(taskCharge, value, estimatedCost);
      setFormData({
        ...formData,
        units: value,
        estimatedCost: estimatedCost.toFixed(2)
      });
    } else {
      // For other form fields, just update the form data
      setFormData({ ...formData, [name]: value });
    }
  };


  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({ ...formData, attachments: files });
    setAttachmentNames(files.map((file) => file.name));
  };

  const handleSubmit = () => {
    const { taskType, taskName, taskDescription, units } = formData;

    const unitsMsg = formData.taskType === "Kit Assembly" ? "# of Kits" : "# of Units";

    // Validate form fields
    if (!taskType || !taskName || !taskDescription || !units) {
      const missingField = !taskType ? "Task Type"
        : !taskName ? "Task Name"
          : !taskDescription ? "Task Description"
            : unitsMsg;

      toast({
        position: "bottom-right",
        title: `${missingField} is required.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Validate product SKU and quantity
    const invalidProduct = products.some(product => product.sku && !product.quantity);
    if (invalidProduct) {
      toast({
        title: "Error",
        description: "Please provide a quantity for all SKUs.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Stop form submission
    }

    const token = localStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };
    const formDataToSend = new FormData();

    // Append form fields to FormData
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });

    // Append files to FormData
    formData.attachments.forEach((file) => {
      formDataToSend.append("attachments", file);
    });

    // Filter out empty products (rows with empty sku and quantity)
    const filteredProducts = products.filter(product => product.sku && product.quantity);

    // If there are valid products, append them as JSON string
    if (filteredProducts.length > 0) {
      formDataToSend.append("products", JSON.stringify(filteredProducts));
    }

    axios.post(`${constants.apiUrl}/customer/add-task`, formDataToSend, {
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    })
      .then((response) => {
        if (response.status === 201) {
          toast({
            position: "bottom-right",
            title: "Task Created successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            position: "bottom-right",
            title: "Error Creating Task, Please try again!",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error creating task:", error);
        toast({
          position: "bottom-right",
          title: "Error creating task!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };



  // Fetch data for dropdowns
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const headers = { Authorization: `Bearer ${token}` };

    // Fetch task types and priorities
    Promise.all([
      axios.get(`${constants.apiUrl}/customer/task-type`, { headers, withCredentials: true }),
      axios.get(`${constants.apiUrl}/customer/task-priority`, { headers, withCredentials: true }),
      axios.get(`${constants.apiUrl}/customer/task-charges`, { headers, withCredentials: true }),
    ])
      .then(responses => {
        setTaskTypeData(responses[0]?.data || []);
        setJobPriorityData(responses[1]?.data || []);
        setTaskChargesData(responses[2]?.data || []);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Fetch product SKUs when task type is "Kit Assembly"
  useEffect(() => {
    if (formData.taskType === "Kit Assembly") {
      const token = localStorage.getItem("accessToken");
      const headers = { Authorization: `Bearer ${token}` };

      axios.get(`${constants.apiUrl}/customer/client-products`, { headers, withCredentials: true })
        .then(response => {
          const fetchedProducts = response.data;
          setFetchedSKUs(fetchedProducts); // Store fetched SKUs
          // Reset products to have one empty row for user to add new products
          setProducts([{ sku: '', quantity: '' }]);
        })
        .catch(error => {
          console.error("Error fetching products:", error);
        });
    }
  }, [formData.taskType]);

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...products];

    // Check if the SKU already exists in another row
    if (field === 'sku' && updatedProducts.some((product, i) => product.sku === value && i !== index)) {
      toast({
        position: "bottom-right",
        title: "Duplicate SKU",
        description: "This SKU is already selected in another row.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      updatedProducts[index][field] = value;
      setProducts(updatedProducts);
    }
  };

  const addProductRow = () => {
    // Check for duplicate SKUs before adding a new row
    const skuSet = new Set(products.map((product) => product.sku));
    if (skuSet.size !== products.length) {
      toast({
        position: "bottom-right",
        title: "Duplicate SKU",
        description: "Please ensure all SKUs are unique before adding a new row.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setProducts([...products, { sku: '', quantity: '' }]);
    }
  };


  // Remove a product row
  const removeProductRow = (index) => {
    if (products.length > 1) {
      const updatedProducts = products.filter((_, i) => i !== index);
      setProducts(updatedProducts);
    }
  };

  return (
    <Card mt={4} p={4} backgroundColor="white">

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Stack spacing={4}>
          {/* Task Type */}
          <Select
            placeholder="Select Task Type"
            name="taskType"
            value={formData.taskType}
            onChange={handleChange}
          >
            {taskTypeData.length > 0 ? taskTypeData.map((taskType) => (
              <option key={taskType.task_type} value={taskType.task_type}>
                {taskType.task_type}
              </option>
            )) : <option>No Task Types Available</option>}
          </Select>

          {/* Task Name */}
          <Input
            placeholder="Task Name"
            name="taskName"
            value={formData.taskName}
            onChange={handleChange}
          />

          {/* Task Description */}
          <Textarea
            placeholder="Task Description"
            name="taskDescription"
            value={formData.taskDescription}
            onChange={handleChange}
          />
          <Flex gap={4}>
            {/* Number of Units */}
            <Input
              type="number"
              placeholder={formData.taskType === "Kit Assembly" ? "# of Kits" : "# of Units"}
              name="units"
              value={formData.units}
              onChange={handleChange}
            />

            {/* Priority */}
            <Select
              placeholder="Select Priority"
              name="priority"
              value={formData.priority}
              onChange={handleChange}
            >
              {jobPriorityData.length > 0 ? jobPriorityData.map((priority) => (
                <option key={priority.task_priority} value={priority.task_priority}>
                  {priority.task_priority}
                </option>
              )) : <option>No Priorities Available</option>}
            </Select>
          </Flex>

          {/* Product Selection */}
          {formData.taskType === "Kit Assembly" && (
            <Box>
              <Text color={textColor} fontWeight="700" >Select Products for KIT</Text>
              {products.map((product, index) => (
                <Flex key={index} alignItems="center" mb={2}>
                  <Select
                    placeholder="Select SKU"
                    value={product.sku}
                    onChange={(e) => handleProductChange(index, 'sku', e.target.value)}
                  >
                    {/* Render fetched SKUs as options */}
                    {fetchedSKUs.length > 0 ? fetchedSKUs.map((sku) => (
                      <option key={sku} value={sku}>{sku}</option>
                    )) : ""}
                  </Select>
                  <Input
                    placeholder="Qty"
                    type="number"
                    min="1"
                    value={product.quantity}
                    onChange={(e) => handleProductChange(index, 'quantity', Math.max(1, Number(e.target.value)))}
                    width="100px"
                    ml={2}
                  />

                  <Button onClick={() => removeProductRow(index)} colorScheme="red" ml={2}>-</Button>
                </Flex>
              ))}
              <Button onClick={addProductRow} colorScheme="blue" mt={2}>+</Button>
            </Box>
          )}


          {/* Attachments */}
          <Box>
            <Button as="label" htmlFor="file-upload" cursor="pointer" colorScheme="blue">
              {attachmentNames.length > 0 ? `Selected: ${attachmentNames.length} files` : "Add Attachments"}
            </Button>
            <Input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              accept="application/pdf, image/*"
              multiple
              hidden
            />
            {attachmentNames.length > 0 && (
              <Box mt={2}>
                <Text>Selected Files:</Text>
                <UnorderedList>
                  {attachmentNames.map((name, index) => (
                    <ListItem key={index}>{name}</ListItem>
                  ))}
                </UnorderedList>
              </Box>
            )}
          </Box>

          {/* Video URL */}
          <Input
            placeholder="Video URL"
            name="videoUrl"
            value={formData.videoUrl}
            onChange={handleChange}
          />
          {formData.taskType !== "Kit Assembly" && (
            <Box>
              <Text color={textColor} fontSize="20px" fontWeight="900" >Estimated Cost: £{formData.estimatedCost === "NaN" ? 0.00 : formData.estimatedCost} </Text>
            </Box>
          )}

          {/* Submit Button */}
          <Button colorScheme="teal" onClick={handleSubmit}>
            Create Task
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};

export default AddTask;
